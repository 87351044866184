/* eslint-disable */
// import Vue from 'vue'
import {
  createWebHistory,
  createRouter
} from 'vue-router'
function load(component) {
  return () => import(`@/views/${component}.vue`)
}
let getLocal = localStorage.getItem('local')  != null ? localStorage.getItem('local') : 'en'
const routes = [
  // Language global
  {
    path:'/',
    redirect: '/'+getLocal
  },
  {
    path: '/:locale',
    // beforeEnter: Trans.routeMiddleware,
    children: [
      // home page
      {
        path: '',
        component: load('Index'),
        children: [{
            path: '',
            name: 'Home',
            meta:{
              title: 'Vanchhhai Chhin'
            },
            component: load('Home'),
          },
          // {
          //   path: 'about',
          //   name: 'About',
          //   component: load('About'),

          // },
        ]
      },
      // auth page
      // {
      //   path: 'auth',
      //   component: load('Auth'),
      //   meta: {
      //     requiresAuth: false,
      //   },
      //   children: [
      //     {
      //       path: 'profile',
      //       name: 'profile',
      //       component: load('auth/Profile'),

      //     },
      //   ]
      // }
    ]
  },
  // not found
  {
    path: '/404',
    name: 'PageNotExist',
    component: () => import('@/views/NotFound.vue'),
  },
  {
      path: '/:catchAll(.*)',
      redirect: '/404',
      
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes, //same --- > routes:routes
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title
  next()

})
// router.afterEach((to, from, next) => {
//   next()

// })
export default router